import "./Articles.css";
import { Helmet } from "react-helmet";
import {
  ATRICLE_SEVEN_CAKE,
  ATRICLE_SEVEN_CAKE_1,
  ATRICLE_SEVEN_CAKE_2,
  LOCAL_CAKE,
} from "components/utils/constants/urlConstants";
import Footer from "../Footer/Footer";
// eslint-disable-next-line quotes

const ArticleSeven = () => {
  return (
    <>
      <Helmet>
        <title>Top 3 Local Cake Flavours We Know You Love</title>
        <meta
          name="description"
          content="Singapore's amazing food scene is a reflection of our diverse culture and history."
        />
      </Helmet>
      <div className="m-auto max-w-[900px] bg-primary-cream-20 px-8 py-10 lg:py-20 lg:px-0 flex items-center justify-center flex-col">
        <img
          loading="lazy"
          src={LOCAL_CAKE}
          alt="halal-cake"
          className="object-cover w-full h-[300px] lg:w-[750px] lg:h-[600px]"
        />
        <div className="max-w-[750px] mt-6">
          <h1 className="blog-title-60 !text-left ">
            Top 3 Local Cake Flavours We Know You Love
          </h1>

          <p className="para-25 !text-left mt-6">
            Hey there, dessert fans! Singapores&apos; amazing food scene is a
            reflection of our diverse culture and history. The rich,
            multicultural cuisine we enjoy today is thanks to the hard work and
            creativity of migrants who helped build this nation. Singapore is a
            melting pot of flavors and tastes, and our local dishes have evolved
            into unique staples that we all adore. From chilli crab to Hainanese
            chicken rice, our main dishes are legendary. But let&apos;s not
            forget about our love for sweets! Here at Pinch Bakehouse,
            we&apos;ve got some local cake flavours that are sure to hit the
            spot. Ready to dive into some deliciousness? Let’s go!
          </p>
          <img
            loading="lazy"
            src={ATRICLE_SEVEN_CAKE}
            alt="halal-cake"
            className="object-cover w-full h-[300px] lg:w-[750px] lg:h-[600px] mt-6"
          />
          <h2 className="title-40 !text-left mt-6">1. Milo Cake</h2>
          <p className="para-25 !text-left mt-6">
            Who doesn’t love Milo, right? This chocolatey malt drink is a
            childhood favorite for many of us. We decided to bring that
            nostalgic taste into cake form, and boy, was it a hit! Our Milo Cake
            is rich, moist, and packed with that iconic Milo flavor. It’s like
            taking a trip down memory lane with every bite. Perfect for
            birthdays, special occasions, or just because you’re craving
            something sweet and familiar. Plus, it’s halal, so everyone can
            enjoy it!
          </p>
          <img
            loading="lazy"
            src={ATRICLE_SEVEN_CAKE_1}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-full lg:h-[500px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">2. Salted Caramel Cake</h2>

          <p className="para-25 !text-left mt-6">
            Next up, let’s talk about our Salted Caramel Cake. Salted caramel
            has taken the dessert world by storm, and for good reason. The
            combination of sweet and salty is absolutely irresistible. Our
            version takes it to the next level with layers of soft, fluffy cake
            and luscious salted caramel frosting. The balance of flavors is spot
            on, making it a crowd favorite at any gathering. Whether it’s a
            family celebration or a treat for yourself, this cake is sure to
            impress.
          </p>
          <img
            loading="lazy"
            src={ATRICLE_SEVEN_CAKE_2}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-full lg:h-[500px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">3. Ondeh Ondeh Cake</h2>
          <p className="para-25 !text-left mt-6">
            Last but definitely not least, we have our Ondeh Ondeh Cake.
            Inspired by the traditional Malay kuih, this cake is a homage to one
            of Singapore’s most beloved desserts. Imagine a cake infused with
            pandan flavor, filled with luscious gula melaka (palm sugar) and
            coated with coconut flakes. It’s like biting into a piece of
            heritage with every forkful. The Ondeh Ondeh Cake is not just a
            treat; it’s a celebration of our rich cultural heritage.
          </p>
          <h2 className="title-40 !text-left mt-6">
            Singapore&apos;s Sweet Tooth: A Love Affair with Desserts
          </h2>
          <p className="para-25 !text-left mt-6">
            It’s no secret that Singaporeans have a serious love for desserts.
            Our local cake flavors at Pinch Bakehouse reflect that passion and
            our commitment to bringing you the best of both worlds – traditional
            flavors and modern baking techniques. What makes us stand out even
            more is our commitment to inclusivity. We’re a halal-certified
            bakery, which means our cakes are accessible to our Muslim friends
            too. We’re all about making sure everyone can enjoy a slice of
            happiness. When you choose Pinch Bakehouse, you’re not just getting
            a cake—you’re getting an experience. From the moment you place your
            order to the first bite of your delicious cake, we’re dedicated to
            making your celebration as sweet as possible. Our friendly team is
            always here to help you find the perfect cake for any occasion.
          </p>
          <h2 className="title-40 !text-left mt-6">Conclusion</h2>
          <p className="para-25 !text-left mt-6">
            So there you have it—three local cake flavors that we know you’ll
            love, brought to you by Pinch Bakehouse. Whether you’re a fan of the
            classic Milo, the trendy Salted Caramel, or the traditional Ondeh
            Ondeh, we’ve got something for everyone. And with our commitment to
            halal certification, allergy-friendly options, customisation, and
            gorgeous packaging, we’re here to make sure every celebration is a
            little sweeter. Ready to try the best halal cakes in Singapore?
            Visit Pinch Bakehouse today and discover your new favorite dessert.
            We can’t wait to be a part of your celebrations and share a little
            pinch of love with you. See you soon!
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleSeven;

import { Accordion, Button, Icon, Image, List } from "semantic-ui-react";
import { useState } from "react";
import {
  NAVBAR_GIFTS_ONE,
  NAVBAR_GIFTS_TWO,
} from "components/utils/constants/urlConstants";
import PropTypes from "prop-types";
import { SidebarHeader } from "./SidebarHeader";
import "./MobileSidebar.css";
import "./GiftsSidebar.css";
import "../DesktopNavbar/DesktopNavbar.css";

const GiftsSidebar = ({ toggleMiniSidebar }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <div className="absolute bg-primary-cream sidebar px-9 py-4 overflow-hidden sidebar-animate-right">
      <SidebarHeader toggleMiniSidebar={toggleMiniSidebar} />
      <div className="mobile-inter-15 font-semibold text-center mb-5 ">
        GIFTS
      </div>

      <div>
        <Accordion>
          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between "
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
          >
            <div>GIFTS BY PRICE</div>
            <Icon className="text-[1.5rem]" name="dropdown text-end" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <ul>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Gifts under $10 </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Gifts under $25 </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Gifts under $50</a>
              </li>
            </ul>
          </Accordion.Content>
          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2"
            active={activeIndex === 1}
            index={1}
            onClick={handleClick}
          >
            <div>GIFTING EXTRAS</div>
            <Icon name="dropdown text-end" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <ul>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Gift Vouchers </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Party Kit </a>
              </li>
            </ul>
          </Accordion.Content>
        </Accordion>

        <div className="mt-10">
          <Button className="customised-btn mobile-inter-15 font-semibold  ">
            Customise
            <Image
              loading="lazy"
              className="giftbox"
              alt="giftbox Logo"
              src="/assets/images/icons/PINCH_icon_gifting.png"
              size="small"
            />
          </Button>
        </div>
        <hr className="border-solid border-t-1   border-[#6d6d6d] mb-8 mt-10" />
        <div className="mt-10 ">
          <Image
            loading="lazy"
            className="mb-1 mobile-navbar-images"
            alt="Packaging image"
            size="large"
            src={NAVBAR_GIFTS_ONE}
          />
          <List.Item
            className="mobile-headline-20   navbar-image-text-link "
            href="#"
            active
          >
            Shop Christmas Gifts
          </List.Item>
        </div>

        <div className="mt-10 mb-14">
          <Image
            loading="lazy"
            alt="Packaging & cake image"
            className=" mb-1 mobile-navbar-images "
            size="large"
            src={NAVBAR_GIFTS_TWO}
          />
          <List.Item
            className="mobile-headline-20 navbar-image-text-link"
            href="#"
            active
          >
            Thank you Gifts
          </List.Item>
        </div>
      </div>
    </div>
  );
};

export default GiftsSidebar;

GiftsSidebar.propTypes = {
  toggleMiniSidebar: PropTypes.func.isRequired,
};

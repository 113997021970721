import {
  ACCOUNT,
  // ARTICLE_EIGHT,
  ARTICLE_ELEVEN,
  ARTICLE_FIVE,
  ARTICLE_FOUR,
  ARTICLE_FOURTEEN,
  ARTICLE_NINE,
  ARTICLE_ONE,
  ARTICLE_SEVEN,
  ARTICLE_SIX,
  ARTICLE_TEN,
  ARTICLE_THIRTEEN,
  ARTICLE_THREE,
  ARTICLE_TWELVE,
  ARTICLE_TWO,
  CART_PAGE,
} from "./routeConstants";
import {
  ALL_CAKES_BANNER_IMAGE,
  // ALL_CAKES_NAVBAR_IMAGE,
  BLOG_10_IMAGES,
  BLOG_11_IMAGES,
  BLOG_12_IMAGES,
  BLOG_13_IMAGES,
  BLOG_14_IMAGES,
  BLOG_9_IMAGES,
  CHILDREN_DAY,
  CLASSIC_CAKE_BANNER_IMAGE,
  CUSTOMISED_CAKES_BLOG,
  CUSTOMISED_CAKE_BANNER_IMAGE,
  FIVE_REASONS,
  LOCAL_CAKE,
  SEASONAL_CAKE_BANNER_IMAGE,
  THE_BEST_HALAL,
  THE_SWEET_WORLD,
  WHAT_MAKES_HALAL,
} from "./urlConstants";

export const GIFT_CARD_PRICE = 2.5;

export const HEADER_MSG =
  "We offer same-day delivery for orders placed before 11:00 am (SGT).";
export const ourBestsellersContent = {
  title: "Our Bestsellers",
  description:
    "Halal-certified cakes and pastries baked using the finest ingredients, perfect for gifting and sharing.",
  btnLabel: "Shop All",
};
export const DESKTOP_MIN_WIDTH = 1024;
export const IPAD_MIN_WIDTH = 768;

export const pinchLove = {
  title: "An extra pinch of love",
  description:
    "Curate a custom order with our dedicated team. From the layers of decoration to the packaging, share your vision, and we will see to it.",
  btnLabel: "Customise an order",
};
export const conciergeSection = {
  title: "Hey there!",
  description:
    "Enjoy a seamless and fun purchasing experience with our curated selection of customizable bakes for gifting and events.",
};
export const howItWorkSteps = {
  step1: {
    title: "Complete our customisation form",
    description:
      "Let us understand what desserts you need, how many, for what purpose, and when",
    btnName: "Customise an order",
  },
  step2: {
    title: "Chat with us",
    description:
      "You will have access to our assistants who guide you through the process, so no one or nothing gets lost amidst all the excitement.",
  },
  step3: {
    title: "Finalise your order",
    description: "Make your final payment after confirmation of the details.",
  },
  step4: {
    title: "Look forward to delivery",
    description: "We will send updates and proof of the successful delivery.",
  },
};
export const workingTough = {
  title: "Let's make corporate gifting easier!",
  description:
    "Love cake? We do too! We're passionate about creating delicious treats and providing top-notch service. Planning a company event or want to send sweet gifts? We've got you covered! You can order classic cakes, cupcakes, or brownies for delivery or pick-up.  Looking for something extra special? Get in touch to discuss a custom order!",
  btnName: "Contact Us",
};
export const shopOccacion = {
  title: "What's the Occasion?",
  description:
    "Want to give the perfect gift for any occasion? Make your shopping experience fun and easy. Go ahead and check it out now!",
};
export const findUsOnInsta = {
  titleInsta: "Find us on Instagram",
  descriptionInsta:
    "Baked goodies straight to your feed and savour the most updated flavour at - @pinchbakehouse",
};
export const ourBestsellers = {
  title: "Our Bestsellers",
};
export const footerDes = {
  policy: "Terms of Service | Privacy Policy",
  copyRight: "2024 Copyright. Pinch Bakehouse Pte Ltd",
  subscribeMsg: "To receive updates and for rewards",
};

// Navbar List
export const listCelebrationCakes = [
  { name: "Cakes for Women", path: "/categories" },
  { name: "Cakes for Men", path: "/categories" },
  { name: "Cakes for Boys", path: "/categories" },
  { name: "Cakes for Girls", path: "/categories" },
];

export const listSeasonalCakes = [
  { name: "Halloween", path: "/categories" },
  { name: "Diwali", path: "/categories" },
  { name: "Christmas", path: "/categories" },
  { name: "Chinese New Year", path: "/categories" },
];

export const listAllDesserts = [
  {
    name: "All Pastries",
    path: "/abc",
  },
  {
    name: "Mini Bites",
    path: "/abc",
  },
  {
    name: "Popsicle Cakes",
    path: "/abc",
  },
  {
    name: "Cupcakes",
    path: "/abc",
  },
  {
    name: "Brownies",
    path: "/abc",
  },
  {
    name: "Mix and Match",
    path: "/abc",
  },
];

export const listByOccasion = [
  { name: "Afternoon Tea", path: "/abc" },
  { name: "Thank you", path: "/abc" },
  { name: "Surprise Celebration", path: "/abc" },
  { name: "Birthday Celebration", path: "/abc" },
];
export const listGiftsByPrice = [
  { name: "Gifts under $10", path: "/categories" },
  { name: "Gifts under $20", path: "/categories" },
  { name: "Gifts under $30", path: "/categories" },
  { name: "Gifts under $50", path: "/categories" },
];
export const listGiftingExtras = [
  { name: "Gift Vouchers", path: "/categories" },
  { name: "Party Kit", path: "/categories" },
];

export const cakesTabContent = [
  {
    title: "All Cakes",
    description:
      "Sing “Happy Birthday” with Halal-certified cakes that taste as good as they look. Fuss-free islandwide delivery – guaranteed deliciousness delivered straight to your door.",
    image: ALL_CAKES_BANNER_IMAGE,
    breadcrumb: [
      { key: "Home", content: "Home", link: true, href: "/" },
      { key: "All Cakes", content: "All Cakes", active: true },
    ],
  },
  {
    title: "Seasonal Cakes",
    description:
      "Let's celebrate our amazing father this Father's Day. Here are some special treats to show him how loved and appreciated he is!",
    image: SEASONAL_CAKE_BANNER_IMAGE,
    breadcrumb: [
      { key: "Home", content: "Home", link: true, href: "/" },
      { key: "Seasonal Cakes", content: "Seasonal Cakes", active: true },
    ],
  },
  {
    title: "Customised Cakes",
    description:
      "Here at PINCH, we are passionate about creating unforgettable cakes! We use only the finest ingredients and baking methods to create delicious and one-of-a-kind treats that you will never forget. Each Cake is designed to impress not only your taste buds but also your guests!.",
    image: CUSTOMISED_CAKE_BANNER_IMAGE,
    breadcrumb: [
      { key: "Home", content: "Home", link: true, href: "/" },
      { key: "Customised Cakes", content: "Customised Cakes", active: true },
    ],
  },
  {
    title: "Classic Cakes",
    description:
      "Indulge with our classic cake collection, decorated and freshly made daily using only the finest ingredients! Classic Cakes are available for next-day delivery within Singapore. Contact our friendly customer success team and order now!",
    image: CLASSIC_CAKE_BANNER_IMAGE,
    breadcrumb: [
      { key: "Home", content: "Home", link: true, href: "/" },
      { key: "Classic Cakes", content: "Classic Cakes", active: true },
    ],
  },
  {
    title: "Celebration Cakes",
    description:
      "Pinch Bakehouse in Singapore elevates cake customization, offering special Halal-certified creations for all ages. We emphasize healthier, less sugary options without sacrificing flavor. Our bakers craft cakes that create sweet memories, especially focusing on dietary needs. If you seek tailor-made, delicious Halal-certified cakes, we’re your destination. Contact us for unforgettable cake experiences!",
    image:
      "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/f4acb3f1-a67f-4993-0a1b-2b87ff40d500/public",
    breadcrumb: [
      { key: "Home", content: "Home", link: true, href: "/" },
      { key: "Celebration Cakes", content: "Celebration Cakes", active: true },
    ],
  },
  {
    title: "Mini Cakes",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel faucibus urna. Morbi sem elit, volutpat vitae lorem ac, porttitor efficitur dolor. Sed ac porttitor diam. Praesent iaculis, magna porta posuere cursus, nulla augue maximus erat, a suscipit odio. Nullam vel faucibus urna. Morbi sem elit.",
    image:
      "https://imagedelivery.net/kAv2lbEaDpLeRztonIqp7A/f4acb3f1-a67f-4993-0a1b-2b87ff40d500/public",
    breadcrumb: [
      { key: "Home", content: "Home", link: true, href: "/" },
      { key: "Mini Cakes", content: "Mini Cakes", active: true },
    ],
  },
];

export const allBakesSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "All Bakes", content: "All Bakes", active: true },
];
export const checkoutSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "Cart", content: "Cart", link: true, href: CART_PAGE },
  { key: "Checkout", content: "Checkout", active: true },
];
export const orderConfirmSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "Cart", content: "Cart", link: true, href: CART_PAGE },
  { key: "Information", content: "Information", href: ACCOUNT },
  { key: "Payment", content: "Payment", href: "/payment" },
  { key: "Order Confirmation", content: "Order Confirmation", active: true },
];
export const retryPaymentSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "Cart", content: "Cart", link: true, href: CART_PAGE },
  { key: "Information", content: "Information", href: ACCOUNT },
  { key: "Payment", content: "Payment", href: "/payment" },
  { key: "Retry Payment", content: "Retry Payment", active: true },
];
export const faqSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "FAQ", content: "FAQ", active: true },
];
export const enquiriesSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "Enquiries", content: "Enquiries", active: true },
];
export const policySections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "Privacy Policy", content: "Privacy Policy", active: true },
];
export const termsConditionsSections = [
  { key: "Home", content: "Home", link: true, href: "/" },
  { key: "Terms & Conditions", content: "Terms & Conditions", active: true },
];

export const cakesSizesInfo = [
  [
    {
      title: '6"cake:',
      titleInfo: "Feeds approx. 5 - 8 people",
    },
    {
      title: '8"cake:',
      titleInfo: "Feeds approx. 8 - 10 people",
    },
    {
      title: '10"cake:',
      titleInfo: "Feeds approx. 10 - 12 people",
    },
  ],
  [
    {
      title: "Double tier (4” + 6” cake):",
      titleInfo: " Feeds approx. 20 people",
    },
    {
      title: "Double tier (6” + 8” cake):",
      titleInfo: " Feeds approx. 40 people",
    },
    {
      title: "Double tier (8” + 10” cake):",
      titleInfo: " Feeds approx. 60 people",
    },
  ],
  [
    {
      title: "Triple tier (4” + 6” + 8” cake):",
      titleInfo: "Feeds approx. 80 people",
    },
    {
      title: "Triple tier (6” + 8” + 10” cake):",
      titleInfo: "Feeds approx. 100 people",
    },
  ],
];

export const bakesSizesInfo = [
  {
    title: "Brownies:",
    titleInfo: 'Portions are approx 2” square and 1.5" tall',
  },
  {
    title: "Cupcakes:",
    titleInfo: 'Standard cupcake size, approx 2.5"',
  },
  {
    title: "Mini cupcakes:",
    titleInfo: 'Approx 1.5"',
  },
  {
    title: "Mini Bites:",
    titleInfo: 'Approx 4"',
  },
  {
    title: "Mini Tarts:",
    titleInfo: 'Approx 4"',
  },
  {
    title: "Popsicle Cakes:",
    titleInfo: 'Approx 2"',
  },
];
export const BLOCKED_DATE_WARNING =
  "Selected date is not available. Please choose another date.";
export const OTP_VERIFY_SUCCESS = "OTP verified successfully";
export const FREE_DELIVERY_PRICE = 150;
export const TAX_MSG = "Tax included. Shipping calculated at checkout.";
export const FREE_DEL_MSG = (price) =>
  `You are just $${(FREE_DELIVERY_PRICE - price)?.toFixed(
    2,
  )} away from receiving Free Delivery.`;
export const FREE_DEL_SUCCESS = "Congrats! You are eligible for free delivery.";
export const RESTRICT_PINCODE =
  "Please note that we are not offering delivery in this area from 19th-24th September 2024 as there will be road closure due to F1.";
export const VIEW_CART = "View Cart";
export const FLEXI_TIMESLOT = "9:00am - 6:30pm";
export const COLLECTION_TIMESLOTS = {
  [process.env.REACT_APP_REGULAR_DELIVERY]: [
    "9:00am - 1:00pm",
    "12:00pm - 4:00pm",
    "3:00pm - 6:30pm",
  ], // Regular delivery
  [process.env.REACT_APP_FLEXI_DELIVERY]: [], // Flexi delivery
  [process.env.REACT_APP_SELF_COLLECT]: ["9:00am - 1:00pm", "12:00pm - 4:30pm"], // Self collect
  [process.env.REACT_APP_SPECIFIC_DELIVERY]: [
    // Specific delivery
    "9:00am - 10:00am",
    "10:00am - 11:00am",
    "11:00am - 12:00pm",
    "12:00pm - 1:00pm",
    "1:00pm - 2:00pm",
    "2:00pm - 3:00pm",
    "3:00pm - 4:00pm",
    "4:00pm - 5:00pm",
    "5:00pm - 6:00pm",
  ],
};
export const NEXT_DAY_COLLECTION_TIMESLOTS = {
  [process.env.REACT_APP_REGULAR_DELIVERY]: ["3:00pm - 6:30pm"], // Regular delivery
  [process.env.REACT_APP_FLEXI_DELIVERY]: [], // Flexi delivery
  [process.env.REACT_APP_SELF_COLLECT]: ["12:00pm - 4:30pm"], // Self collect
  [process.env.REACT_APP_SPECIFIC_DELIVERY]: [
    // Specific delivery
    "2:00pm - 3:00pm",
    "3:00pm - 4:00pm",
    "4:00pm - 5:00pm",
    "5:00pm - 6:00pm",
  ],
};

export const DELIVERY_CHARGES = {
  free: 0,
  specific: 35,
  regular: 15,
  flexi: 9.9,
  standardTuaxFee: 30,
  specificTuaxFee: 45,
};

export const DELIVERY = {
  date: "Delivery date is required",
  method: "Delivery method is required",
  time: "Delivery time is required",
};

export const SAME_DAY_DELIVERY_ERR =
  "Delivery date should be greater than today's date.";

export const NEXT_DAY_DEL_TIMESLOT_ERR =
  "Currently we don't accept morning timeslot for next day orders. Please select morning or evening timeslots.";

export const SELF_COLLECT_ADDRESS =
  "Self-collect : Level 1 reception, 218 Pandan Loop, Xpace, Singapore 128408 (Open from 10am - 4:30pm Daily, By Appointment Only)";

export const TUAX_CHARGES_INFO =
  "Surcharge for Jurong Island & Sentosa - Flat delivery fee of $45 for Specific delivery and $30 for Flexi/Regular delivery.";

export const AUTH_401 = "Please sign in again";
export const TUAS_PINCODES = [
  "098679",
  "098941",
  "098942",
  "098943",
  "098967",
  "098968",
  "098969",
  "098970",
  "098971",
  "098972",
  "098973",
  "098974",
  "098975",
  "098995",
  "098996",
  "098997",
  "098998",
  "098999",
  "099000",
  "099001",
  "099002",
  "099003",
  "099004",
  "099005",
  "099006",
  "099007",
  "099008",
  "099009",
  "099010",
  "099011",
  "099012",
  "099013",
  "098267",
  "098268",
  "098269",
  "098271",
  "098530",
  "099598",
  "098533",
  "098534",
  "098529",
  "098527",
  "098535",
  "098435",
  "098361",
  "098139",
  "098449",
  "098448",
  "098239",
  "098163",
  "098253",
  "098145",
  "098506",
  "099960",
  "098338",
  "098531",
  "098135",
  "098532",
  "098528",
  "098136",
  "098140",
  "098521",
  "099339",
  "099374",
  "099951",
  "098978",
  "098510",
  "098175",
  "098486",
  "819666",
  "819829",
];

export const faqQuestionsList = [
  {
    id: "product-storage-title",
    faqSectionTitle: "Storage and Delivery",
    accordianContent: [
      {
        title: "Where do you deliver and how much does it cost?",
        content1:
          "Hi! Most of our treats can be delivered all over Singapore! We deliver them fresh and fast, straight from our bakery. We use the finest ingredients to ensure these yummy goodies arrive safely and on time at your doorstep. Check out our delivery options below to find the perfect way to get your sweet tooth fix in a flash!",
        content2: "Monday to Sunday Time Slot:",
        contentList: [
          "• Flexi Delivery: 9am - 6:30pm delivery (orders below $150) - $9.90",
          "• Regular Delivery: 9am - 1pm, 12pm - 4pm, 3pm - 6:30pm (orders below $150) - $15.00",
          "• Regular Delivery: 9am - 1pm, 12pm - 4pm, 3pm - 6:30pm (orders ABOVE $150) - FREE",
          "• Flat Rate: 1-hour range between 9am - 6pm (orders below $150) - $35.00",
        ],
        content3:
          "* Please note that if treats are requested to be delivered in a hotel, chalet, or office building, our team will make a call and drop them off at the nearest car park.",
      },
      {
        title: "What happens if the cake is delivered but one is at home?",
        content1:
          "Don't worry, our awesome team will give the recipient a call to see if we can leave it with a friendly neighbor or guard. If that's not an option, we can definitely try delivering it again for a small fee of $20. Of course, you're always welcome to pick it up yourself at our store too!",
      },
      {
        title: "Can I do a self pick-up? Where are you located?",
        content1:
          "You are always welcome to visit us at 218 Pandan Loop, Xpace, Singapore 128408, level 1 reception, daily, 10 am - 5 pm.",
      },
      {
        title: "How do I store the cake upon receipt?",
        content1: "Storing your delicious cake:",
        contentList: [
          "• Fresh cream cakes: Keep cool in the fridge! They'll last about an hour at room temperature.",
          "• Buttercream cakes: Fridge is best, but if not possible, a cool room works for 2-3 hours. Let them thaw 30 minutes to 1 hour before enjoying for the best flavor.",
        ],
        content3:
          "For ultimate freshness: We recommend enjoying your cake within 48 hours and storing it in the refrigerator for up to 2 days.",
      },
      {
        title: "What's the dimensions of your cake box?",
        content1:
          "Need fridge space? No worries! Boxes come in different sizes to fit your cake. You can message our friendly customer service team anytime for help!",
      },
      {
        title: "How do I cut a tall two-tiered cake?",
        content1: "Cake cutting made easy!",
        content2:
          "Worried about messy slices with a tall cake? We've got you covered! Check out our video for pro-cutting tips",
        content3:
          "Fondant fans? You can easily remove decorations before slicing for a cleaner cut.",
        content4:
          'How many slices? Our servings are just a guide (think 2-3" x 1" x 3.5-4" slices), so cut to your heart\'s content!',
      },
      {
        title: "What if I did not collect my order? Will I be refunded?",
        content1: "Missed your delivery? No worries!",
        content2:
          "We'll call the number indicated in your order to see if we can catch you. If not, you can choose to:",
        contentList: [
          "• Redeliver for $20: We'll try again, but there's a fee.",
          "• Pick it up yourself (within 2 days): It's free, and your cake will be waiting for you! But if you cannot make it, we can deliver at your doorstep for only $35",
        ],
        content3:
          "*Heads up: After 2 days, unclaimed cakes are donated. We can't offer refunds or exchanges in these cases.",
      },
      {
        title: "What if the cake I had ordered turned up damaged?",
        content1:
          "Cake arrive a little worse before the celebration?  No worries! Just snap a pic and contact our friendly customer service at +65 8380 6722 and we will fix it ASAP!",
      },
    ],
  },
  {
    id: "product-faq-title",
    faqSectionTitle: "Product",
    accordianContent: [
      {
        title: "Is Pinch Bakehouse Halal-certified brand?",
        content1: "Yes, Pinch Bakehouse is Halal-certified brand.",
      },
      {
        title: "Are Pinch's products nut-free?",
        content1:
          "Heads up! Our bakery uses facilities that may process nuts. While we take precautions, and for everyones' safety, we don't recommend our treats for those th nut allergies.",
      },
      {
        title: "What are your cake flavours?",
        content1: "Devour our flavor chart!",
      },
      {
        title:
          "What lower in sugar cake flavours would you recommend for diabetics and kids?",
        content1:
          "Diabetic-friendly treats? We've got you covered! Try our Sugar-free brownies & cookies: Made with no sugar, perfect for a guilt-free treat. You may also try our Strawberry Fields & Midnight Chocolate Cakes: Lower in added sugar for a delicious and mindful indulgence.",
      },
      {
        title: "Where can I check out your minibites?",
        content1:
          "Indulge in our delicious cupcakes and bite-sized treats delivered fresh to your door across Singapore! We have all your favorites, from the all-time favorite Crunchy Chocolate Brownie to our newest, mouthwatering creations. Treat yourself or surprise someone special! Browse our selection and discover your new go-to sweet!",
      },
      {
        title: "What are your cake sizes?",
        content1:
          "Looking for the perfect cake size? We have a delicious cake for any size of celebration, from intimate gatherings to big bashes. We even do custom cakes!  See our handy size chart to find your perfect fit",
      },
      {
        title: "When can I select two flavours for my cake?",
        content1: "For 2 flavoured cakes:",
        contentList: [
          '• You can choose any 2 flavors you love with our double-tiered and 8" and 1" (5kg) cakes and above.',
          "• For slightly smaller or double-tiered cakes 6+8” (3kg), pick your favorite flavor, or if your heart desires two, there's a small $15 upcharge to cover the extra ingredients used in smaller batches.",
          '• Three-tiered cakes? The flavor party continues! Cakes in the 4" + 6" + 8" size range let you pick 2 flavors to tantalize your taste buds. For even larger three-tiered cakes, go wild – choose 3 flavors!',
        ],
      },
      // {
      //   title: "How can I customise my own mini cake or bento cake?",
      //   content1: "Devour our mini cakes collection!",
      //   contentList: [
      //     "1. Mini cakes - Suitable for 2-4 paxZ",
      //     "2. Bento cakes - suitable for 1-2 pax (Standard/Korean) ",
      //   ],
      //   content3:
      //     'They cannot be customized but we can accommodate slight color changes. Feel free to change the message above to your own and indicate it under "notes". The fastest way is to purchase it via the link over here: (link to all mini cakes in pinch)',
      // },
      // {
      //   title: "How can I purchase a money pulling cake?",
      //   content1:
      //     "• Our delicious 6-inch single-tier cake is perfect for smaller celebrations and works great with the money pulling mechanism! This size is good for 6-10 people.",
      //   content2:
      //     "Do also take note that these few options are not available for money pulling due to structural issues:",
      //   contentList: [
      //     "Standard Bakes",
      //     "Mini Cakes/Bento Cakes",
      //     "Crown cake",
      //     "Champagne cake",
      //     "3D cakes",
      //     'Money bag cakes - need to be at least 7" and above.',
      //     "Double-tiered cakes need to be at least 6+9 inch double-tiered, 5 kg",
      //   ],
      //   content3:
      //     " There's a $40 charge for adding the money-pulling fun to your cake. Our cakes are designed to minimize wasted cake during installation. Prices start at $158 for a 1kg cake (approximately the size of a 6-inch single tier). The money you pull out is separate from the cake cost and needs to be provided by you. You can choose denominations of $2, $10, and $50 bills, with a maximum of 25 notes.",
      // },
      // {
      //   title:
      //     "Where can I purchase vegan cakes or eggless cakes on your website?",
      //   content1:
      //     " We for now do not carry vegan cakes or eggless cakes. We however do have vegetarian cakes. Our vegetarian cake flavours contain egg and dairy. They do not contain gelatin or rennet.",
      //   content2: "Here are our vegetarian friendly flavours:",
      //   contentList: [
      //     "Sea Salt Salted Caramel",
      //     "Cookies and Cream",
      //     "Signature Pandan Ondeh Ondeh",
      //     "Kaya Butter Cake",
      //     "Strawberry Lemon",
      //     "Classic Milo Gao Siew Dai Chocolate",
      //   ],
      //   content3: "Let us know how else we can help!",
      // },
      // {
      //   title:
      //     "How can I purchase a design cake from you that I didn’t find in your website?",
      //   content1:
      //     "Have a special theme or spotted a cake you just gotta have? No sweat! Just send a pic (or your theme ideas) to our amazing cake crew on WhatsApp (+6588623327).  They'll be happy to chat suggestions and give you a free quote, with no pressure to buy.  Plus, if you have a budget in mind, they'll work their magic to create a custom cake that's perfect for you! ✨",
      //   content3:
      //     "Let's bake up some sweet memories! We can't wait to create your dream cake.",
      // },
      {
        title: "Do your cakes contain raw eggs?",
        content1:
          "Safety first, deliciousness always! We use fully cooked eggs in all our cakes (think 60 degrees!), so you can enjoy every bite without worry.",
      },
      {
        title: "Will candles and a birthday knife be provided?",
        content1:
          "Sing happy birthday in style! Each cake delivery includes a handy birthday knife and a long, shiny gold candle for the big moment.",
      },
      {
        title: "What should I request if I would like my cake lower in sugar?",
        content1:
          "They're 30-50% lower than regular cakes, so you can indulge yourself and your loved ones (especially the little ones and those watching their sugar intake) without any guilt. Moderation is key, of course, but now you can enjoy every delicious bite with a smile!",
      },
      {
        title: "Do you offer cake for testing?",
        content1:
          "Can't decide on a cake size? Worry no more. Our delicious mini classic cakes ($32.90) are the perfect way to try out our flavors before you commit to a bigger cake.  Think of it as a yummy sneak peek!",
      },
      {
        title:
          "I chose chocolate as my cake flavor but I want the color of my cake to be blue. Is it possible?",
        content1:
          "Our cakes are like flavor explosions!  We layer them with delicious fillings in the center, and then frost them all around with a dreamy vanilla buttercream that'll have you wanting more.",
      },
      {
        title:
          "Why are there bubble tea straws, wooden dowels or toothpicks in your cake?",
        content1:
          "Our beautiful tiered cakes have secret helpers inside to keep everything perfectly balanced. These hidden supports are there for safety reasons. Just be sure to remove them before digging in – they're food-safe, but best left for the cake to enjoy!",
      },
      {
        title: "Why don't you make fondant cakes?",
        content1: "We're all about keeping things light and fluffy!",
        content2:
          "Our cakes are specially designed to be moist and delicious, and sometimes heavier decorations like fondant can weigh them down.  Plus, keeping fondant perfect can be tricky!  Singapore's hot weather and humidity can make it melt or get sticky, and we wouldn't want that to happen to your beautiful cake.",
        content3:
          "We use a special low-sugar buttercream that's way lighter than regular cake frosting.  And for those amazing designs, we can use creative decorations like toys or other fun options depending on your cake's theme.",
        content4:
          "So, you can still get a stunning cake that tastes incredible!",
      },
      {
        title:
          "Now that I know you make buttercream cakes with fondant figurines, how can I store my fondant figurines safely after celebration, for memento?",
        content1:
          "Give them a cozy home: Pop your fondant friends in an airtight container. This keeps them fresh and protected.",
        content2:
          "Sunshine is pretty, but not for fondant: Direct sunlight can fade their colors, so keep them in a shady spot.",
        content3:
          "As long as you keep them dry and out of the sun, your fondant figures can last a long time!",
      },
      {
        title: "Where can I browse for gifting to different addresses?",
        content1:
          "Need help finding the perfect gift for a crowd? We've got you covered!  From small treats to grand cakes, we have tons of gifting options to fit any budget.",
        content2:
          "Our awesome customer success crew is here to lend a hand!  Just shoot them an email at hello@pinchbakehouse.com or a WhatsApp message at +65 8380 6722.  All you need to do is send a list of your lucky recipients (a CSV or Excel file works great!), and we'll handle the rest – making sure everything arrives fresh and festive.  Sending everyone a pinch of hapiness!",
      },
      {
        title:
          "How can I purchase a smash cake for my kid’s 1st year photoshoot?",
        content1:
          "Our delicious cakes are made with love, and that includes ingredients like dairy, butter, and eggs. While these are yummy for most, some little ones under 2 might have allergies that show up as rashes.  If you have any concerns, it's always best to check with your pediatrician first.  For everyone else over 2, our cakes are a delicious way to celebrate!",
      },
    ],
  },
  {
    id: "product-order-title",
    faqSectionTitle: "Order and Cancellation",
    accordianContent: [
      {
        title: "Are pastel coloured or darker coloured cakes doable?",
        content1:
          "They can be super fun, but sometimes they can stain your fingers (like colorful markers!) and might taste a little bit stronger than lighter colors because of the extra food coloring.  While we use the safest, top-quality coloring from the USA (totally safe for everyone!), some very young babies (under 1) might have a mild reaction like a rash.",
        content2:
          "Here's the good news! We have tons of amazing pastel and lighter colors that are just as fun and festive, and they won't leave any colorful surprises on your hands or affect the taste.  We even mix a touch of white into some colors to keep them soft and pretty!  So, you can still have a gorgeous cake that's gentle on everyone.",
      },

      {
        title: "What happens if I would like to cancel my order?",
        content1: "Here's a quick rundown of our cancellation policy:",
        contentList: [
          "Plenty of time to spare (more than 1 week before delivery)? No worries! We'll give you a full refund (100% store credit with no expiry) or a 90% cash refund (minus a small merchant fee).",
          "Getting closer to the date (3 days to 1 week before delivery)? We can still offer you a 70% store credit with no expiry.",
          "Cake already in the works (within 3 days of delivery)? Our policy states a 100% cancellation fee, but we know things happen! kindly WhatsApp us at +6588623327 and our friendly customer success will do there best to help given the circumstances.",
        ],
      },
      {
        title: "How long in advance should I place my order?",
        content1:
          "Dreaming of a stunning designer cake? Just let us know at least 1 week in advance, and we'll do our best to make your cake dreams come true.",
        content2:
          "Next-day delivery? Our classic cakes can be whipped up much faster (think 2 days!). We can even do same-day deliveries in some cases - just ask, we are here to help!",
      },
      {
        title:
          "How can I reach out to your team if I am facing difficulties ordering a cake from your website?",
        content1:
          "Something's wrong?  We're here to help!  Just reach out to our awesome customer success team.  They're super friendly and quick to respond - you'll hear back within an hour (usually even faster) on WhatsApp!  Just shoot them a message and we'll get things fixed in no time.",
      },
    ],
  },
];

export const useOfYourInfo = [
  "Administer giveaways",
  "Conduct consumer studies or online polls - only with your consent",
  "Assist law enforcement and respond to subpoena.",
  "Compile anonymous statistical data and analysis for use internally or with third parties.",
  "Create and manage your account.",
  "Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site to you.",
  "Email you regarding your account or order.",
  "Enable user-to-user communications.",
  "Fulfill and manage purchases, orders, payments, and other transactions related to the Site.",
  "Generate a personal profile about you to make future visits to the Site more personalized.",
  "Increase the efficiency and operation of the Site.",
  "Monitor and analyze usage and trends to improve your experience with the Site.",
  "Notify you of updates to the Site.",
  "Offer new products, services, and/or recommendations to you.",
  "Perform other business activities as needed.",
  "Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.",
  "Process payments and refunds.",
  "Request feedback and contact you about your use of the Site.",
  "Resolve disputes and troubleshoot problems.",
  "Respond to product and customer service requests.",
  "Send you a newsletter.",
  "Solicit support for the Site.",
];
export const useOfWebsiteTerms = [
  "This Website is owned and managed by  Pinch Bakehouse Pte Ltd (ROC & GST No. 201833965W). Use of this Website is subject to our Terms and Conditions.",
  "While Pinch Bakehouse makes every effort to ensure that the information available on the Website is accurate, it does not guarantee that the Website is free from errors.  Pinch Bakehouse does not accept responsibility or liability for any errors or omissions; and reserves the right to change information published on the Website at any time.",
  " Pinch Bakehouse does not accept responsibility or liability for any direct, indirect, incidental, special and/or consequential damages or loss of profits of any kind whatsoever which may result from any use or access of, or any inability to use or access, the Website.",
  "This general disclaimer is not restricted by any of the specific warnings and disclaimers in  Pinch Bakehouse’s Terms and Conditions, and nothing in the Terms and Conditions derogates from  Pinch Bakehouse’s right to comply with legal or law enforcement requests or requirements relating to your use of this Website or information provided to or gathered by Pinch Bakehouse with respect to that use.",
];
export const termsAndConditions = [
  "Pinch Bakehouse reserves the right to amend these Terms and Conditions at any time.",
  "In these terms and conditions:",
  "“Pinch Bakehouse” means Pinch Bakehouse Pte Ltd (ROC & GST No. 201833965W);",
  "Ensure“Premises” refers to Level 1 reception and level 5 unit $05-09, 218 Pandan Loop, Xpace, Singapore 128408;",
  "“We” and “us” refers to Pinch Bakehouse Pte Ltd and “our” has the same meaning.",
  "“You” refers to any client, customer or participant of any event and “your” has the same meaning.",
];
export const generalTermsAndConditions = [
  "Pinch Bakehouse reserves the right to make changes to the product, pricing and any other content on the website. The version on the website is the most updated and overrides other versions.",
  "Unless expressly stated otherwise in these Terms and Conditions, all purchases or payments are non-exchangeable, non-refundable and non-transferable to another date or event.",
  "In the unlikely event of unforeseen exceptional circumstances, when Pinch Bakehouse is unable to fulfill an order, the same value of credits will be offered in return.",
  "Occasionally, our staff may take photographic or video images of your cake order for advertising, publicity or sponsorship purposes. If you do not wish for your order to be included in such, please inform us in writing prior to the event, failing which you irrevocably waive any and all rights which you may have now or in the future including but without limitation any of your rights under the Copyright Act as may be amended from time to time or any similar laws of any jurisdiction, and acknowledge Pinch Bakehouse's right to use your name, likeness and voice and any biographical details, photographs and recordings of your order in all media and formats throughout the universe including but not limited to Pinch Bakehouse’s website and in advertising, publicity, or sponsorship.",
  "If any provision, part of any provision, or any part of these Terms and Conditions shall for any reason be properly adjudged by any court or other legal and/ or competent authority to be invalid, illegal or unenforceable in any respect, the legality and enforceability of the remaining provisions or parts contained herein shall not in any way be affected or impaired.",
  "These Terms and Conditions are governed by the laws of Singapore and you irrevocably and unconditionally submit to the jurisdiction of the courts of Singapore.",
];
export const cancellationOfOrders = [
  "Fees for your cake order must be paid in advance in order to secure your booking. Your order is only confirmed once full payment has been received. Once your order is confirmed, you will be subjected to our cancellation/refund/transfer policy.",
  "We do not recommend making changes to your order after payment, to avoid confusion. Pinch Bakehouse reserves the right not to entertain changes 5 days onwards before your delivery date. Payment will be fully forfeited regardless of the unforeseen circumstances.",
];
export const refundPolicy = [
  "No refunds when Cancellations requested within 3 days before the delivery date.",
  "50% store credits refund when Cancellations requested 4-7 days prior to the delivery date",
  "100% store credits refund when Cancellations requested more than 7 days prior to the course start date",
  "We will keep the cake up to 2 days at store, and will dispose with no refund made to you, should there be a no-show when Cakes not collected/Not present during delivery.",
];
export const release = [
  "You agree to comply with and abide by Pinch Bakehouse’s safety and health directions, fire and safety regulations and security arrangements. You will fully and truthfully disclose to Pinch Bakehouse in writing any allergy, medical condition, pregnancy, dietary requirements or other relevant health information pertaining to you at the time of your booking.",
  "By purchasing Pinch Bakehouse's cakes, you agree that you will not use the cake for smashing, or toying in any way.",
  "While Pinch Bakehouse takes all practical and reasonable attempts to minimize any risk of injury to you or your property, you acknowledge the consumption of Pinch Bakehouse’s product is at your own risk. You release and indemnify Pinch Bakehouse, its employees, servants, agents and service providers from any and all claims, actions, suits, proceedings, liabilities, damages, losses, compensation, costs, charges and expenses whatsoever in any way arising out of or resulting from your consumption Pinch Bakehouse's products, without limitation, any and all claims, actions, and liabilities for injury, loss or damage to you, to anyone else or to any property, unless the same was caused by the gross negligence of Pinch Bakehouse and recoverable on that ground following judgment of a competent court of final judgment.",
  "You will reimburse Pinch Bakehouse for any damage to its property caused by yourself/your child/ward (including all equipment and structures within the premises) while you/your child/ward are on the premises.",
  "Pinch Bakehouse accepts no responsibility whatsoever for any property lost on our premises. If any property has been left behind and found by a member of our staff, please arrange with us to collect such property at a convenient time. Any lost property which has not been claimed within 14 days will be disposed of at our discretion.",
];
export const othergeneral = ["Pinch Bakehouse is a Halal-certified brand."];

// Featured Blogs
export const featuredBlogContent = [
  {
    title: "The Best Halal Cakes in Singapore: A Guide to Pinch Bakehouse",
    subTitle:
      "Hey there, cake lovers! It’s such a pain to be on the hunt for halal bakeries in Singapore, right?..",
    navigateLink: ARTICLE_ONE,
    image: THE_BEST_HALAL,
  },
  {
    title: "What Makes a Cake Halal? Understanding Halal Certification",
    subTitle:
      "Hey there, cake lovers! Searching for a halal cake in Singapore can be such a pain, right? Halal bakeries ...",
    navigateLink: ARTICLE_TWO,
    image: WHAT_MAKES_HALAL,
  },
];

// All Blogs
export const allBlogContent = [
  {
    title: "5 Reasons to Get Your Dessert Fix from Pinch Bakehouse",
    subTitle:
      "Hey there, dessert lovers! If you’ve been on the hunt for the perfect halal cake in Singapore, look no further!..",
    navigateLink: ARTICLE_THREE,
    image: FIVE_REASONS,
  },
  {
    title:
      "Halal Cakes - Cake Trends Spotted. What Cake Design Should You Customise for Your Next Party?",
    subTitle:
      "Hey cake lovers! If you’re anything like me, you’re always on the lookout for the latest and greatest cake trends...",
    navigateLink: ARTICLE_FOUR,
    image: CUSTOMISED_CAKES_BLOG,
  },
  {
    title: "The Sweet World of Halal Baking & Pastries: All You Need to Know",
    subTitle:
      "Hey there, fellow cake lovers! Living in Singapore means we’re constantly surrounded by a vibrant mix of....",
    navigateLink: ARTICLE_FIVE,
    image: THE_SWEET_WORLD,
  },
  {
    title: "The Best Halal Cakes in Singapore: A Guide to Pinch Bakehouse",
    subTitle:
      "Hey there, cake lovers! It’s such a pain to be on the hunt for halal bakeries in Singapore, right?..",
    navigateLink: ARTICLE_ONE,
    image: THE_BEST_HALAL,
  },
  {
    title: "What Makes a Cake Halal? Understanding Halal Certification",
    subTitle:
      "Hey there, cake lovers! Searching for a halal cake in Singapore can be such a pain, right? Halal bakeries ...",
    navigateLink: ARTICLE_TWO,
    image: WHAT_MAKES_HALAL,
  },
  {
    title: "Children’s Day Gifts: Halal Certified Treats That Shine in School!",
    subTitle:
      "Hey there, awesome gift-givers and party planners! 🎉 With Children's Day just around the ...",
    navigateLink: ARTICLE_SIX,
    image: CHILDREN_DAY,
  },
  {
    title: "Top 3 Local Cake Flavours We Know You Love",
    subTitle:
      "Singapore's amazing food scene is a reflection of our diverse culture and history ...",
    navigateLink: ARTICLE_SEVEN,
    image: LOCAL_CAKE,
  },
  // {
  //   title:
  //     "  3 Crazy Unique Halal Cake Flavours You’ve Got to Try in Singapore",
  //   subTitle:
  //     "Discover Pinch Bakehouse's three unique halal cake flavours that are making waves in Singapore! ...",
  //   navigateLink: ARTICLE_EIGHT,
  //   image: ALL_CAKES_NAVBAR_IMAGE,
  // },
  {
    title: "Are Halal Cakes More Expensive Than Non-Halal Cakes?",
    subTitle:
      "cake enthusiasts and curious minds! 🎂✨ If you’ve ever wondered whether halal cakes come with a higher price tag  ...",
    navigateLink: ARTICLE_NINE,
    image: BLOG_9_IMAGES,
  },
  {
    title: "How Can I Customize a Halal Cake for Special Occasions?",
    subTitle:
      "cake lovers and celebration enthusiasts! 🎉🍰 If you’re planning a special event and want a cake that’s as unique  ...",
    navigateLink: ARTICLE_TEN,
    image: BLOG_10_IMAGES,
  },
  {
    title: "Tips for Decorating Halal Customised Cakes with Pinch Bakehouse",
    subTitle:
      "Hey there, cake lovers! 🎂 If you’re here, you probably already know how important it is to make sure your  ...",
    navigateLink: ARTICLE_ELEVEN,
    image: BLOG_11_IMAGES,
  },
  {
    title:
      "How to Make Gluten Free Cakes: Your Guide to Gluten Free Flour Blend",
    subTitle:
      "If you’re on a quest to make the perfect gluten free cake, you’re in the right place. At Pinch Bakehouse  ...",
    navigateLink: ARTICLE_TWELVE,
    image: BLOG_12_IMAGES,
  },
  {
    title:
      " Insider Tip from Pinch Bakehouse: Our Top 3 Best Selling Pastries for Your Next Party",
    subTitle:
      "Party planners and pastry lovers! 🎉 If you’re looking to impress your guests with the most ...",
    navigateLink: ARTICLE_THIRTEEN,
    image: BLOG_13_IMAGES,
  },
  {
    title: "Your Guide to Ordering the Perfect Halal Cake Online",
    subTitle:
      "Cake enthusiasts and party planners! 🎉 If you’re wondering how to get a scrumptious halal cake delivered right  ...",
    navigateLink: ARTICLE_FOURTEEN,
    image: BLOG_14_IMAGES,
  },
];

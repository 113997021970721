import { Image, List } from "semantic-ui-react";
import {
  CHRISTMAS_LOG_NAVBAR_IMAGE,
  CHRISTMAS_TREE_NAVBAR_IMAGE,
} from "components/utils/constants/urlConstants";
import PropTypes from "prop-types";
import {
  ALL_BAKES,
  ALL_CAKES,
} from "components/utils/constants/routeConstants";
import { SidebarHeader } from "./SidebarHeader";
import "./MobileSidebar.css";
import "./GiftsSidebar.css";
import "../DesktopNavbar/DesktopNavbar.css";

const OccasionSidebar = ({ toggleMiniSidebar }) => {
  return (
    <div className="absolute bg-primary-cream sidebar px-9 py-4 overflow-hidden sidebar-animate-right">
      <SidebarHeader toggleMiniSidebar={toggleMiniSidebar} />
      <div className="mobile-inter-15 font-semibold text-center mb-5 ">
        CHRISTMAS
      </div>

      <div>
        <div className="mb-14 mt-10">
          <a href={`${ALL_BAKES}?id=${process.env.REACT_APP_SEASONAL_BAKES}`}>
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={CHRISTMAS_TREE_NAVBAR_IMAGE}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link"
            href={`${ALL_BAKES}?id=${process.env.REACT_APP_SEASONAL_BAKES}`}
            active
          >
            All Christmas Bakes
          </List.Item>
        </div>
        <div className="mb-14 mt-10">
          <a
            href={`${ALL_CAKES}?id=1&value=${process.env.REACT_APP_SEASONAL_CAKE}`}
          >
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={CHRISTMAS_LOG_NAVBAR_IMAGE}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link"
            href={ALL_CAKES}
            active
          >
            Christmas Log Cake
          </List.Item>
        </div>
      </div>
    </div>
  );
};

export default OccasionSidebar;

OccasionSidebar.propTypes = {
  toggleMiniSidebar: PropTypes.func.isRequired,
};

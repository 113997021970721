import "./MobileNavbar.css";
import PropTypes from "prop-types";
import { Button, Image } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { UilAngleRightB } from "@iconscout/react-unicons";
import { toggle } from "features/sidebarSlice";
import {
  // ALL_BAKES,
  CONTACT_US,
  FAQ,
  LOGIN,
} from "components/utils/constants/routeConstants";
import { useNavigate } from "react-router-dom";
import { SidebarHeader } from "./SidebarHeader";

export const MobileSidebar = ({
  toggleSidebar,
  toggleMiniSidebar,
  isOpenMiniSidebar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onCustomiseClick = () => {
    toggleMiniSidebar("");
    dispatch(toggle(false));
    navigate(CONTACT_US);
  };

  // const handleChristmasClick = () => {
  //   navigate(`${ALL_BAKES}?id=${process.env.REACT_APP_SEASONAL_BAKES}`);
  //   dispatch(toggle(false));
  // };

  return (
    <div
      className={`absolute bg-primary-cream sidebar px-9 py-4 ${
        toggleSidebar && "sidebar-animate-right"
      }`}
    >
      <SidebarHeader
        toggleMiniSidebar={toggleMiniSidebar}
        isOpenMiniSidebar={isOpenMiniSidebar}
      />

      <div className="flex flex-col justify-between h-[80%] gap-40 bg-primary-cream sidebar">
        <div>
          <div className="mb-16">
            {/* <div className="flex justify-between items-center mb-6  ">
              <div className="mobile-inter-15 font-semibold">CORPORATE</div>
            </div> */}
            {/* <button
              type="button"
              onClick={() => toggleMiniSidebar("Gifts")}
              className="flex justify-between items-center mb-6 btn-width-100 "
            >
              <div type="button" className="mobile-inter-15 font-semibold">
                GIFTS
              </div>
              <UilAngleRightB className="right-arrow-icon" />
            </button> */}
            <button
              type="button"
              onClick={() => toggleMiniSidebar("Cakes")}
              className="flex justify-between items-center mb-6 btn-width-100  "
            >
              <div className="mobile-inter-15 font-semibold ">CAKES</div>
              <UilAngleRightB className="right-arrow-icon" />
            </button>
            <button
              type="button"
              onClick={() => toggleMiniSidebar("Bakes")}
              className="flex justify-between items-center mb-6 btn-width-100 "
            >
              <div className="mobile-inter-15 font-semibold ">BAKES</div>
              <UilAngleRightB className="right-arrow-icon" />
            </button>
            <button
              type="button"
              // onClick={handleChristmasClick}
              onClick={() => toggleMiniSidebar("Christmas")}
              className="flex justify-between items-center mb-6 btn-width-100 "
            >
              <div className="mobile-inter-15 font-semibold ">CHRISTMAS</div>
              <UilAngleRightB className="right-arrow-icon" />
            </button>
          </div>
          <Button
            type="submit"
            onClick={onCustomiseClick}
            className="customised-btn mobile-inter-15 font-semibold  "
          >
            Customise
            <Image
              loading="lazy"
              className="giftbox"
              alt="giftbox Logo"
              src="/assets/images/icons/PINCH_icon_gifting.png"
              size="small"
            />
          </Button>
        </div>

        <div>
          <hr className="border-solid border-t-1  border-[#6d6d6d] mb-12" />
          <div className="flex flex-col">
            <a href={CONTACT_US} className="mobile-inter-15 font-semibold mb-6">
              CONTACT US
            </a>
            <a href={FAQ} className="mobile-inter-15 font-semibold mb-6">
              FAQ
            </a>
            <a href={LOGIN} className="mobile-inter-15 font-semibold mb-6">
              LOGIN
            </a>
          </div>

          <div className="flex mt-5 mb-10 justify-between items-center">
            <div className="flex gap-3 items-center ">
              <a
                className="bg-sidebar-link-logos"
                aria-label="instagram-link"
                href="/"
              >
                <Image
                  loading="lazy"
                  className="sidebar-link-logos"
                  src="/assets/images/icons/instagram-logo.svg"
                  alt="instagram icon"
                />
              </a>
              <a
                className="bg-sidebar-link-facebook"
                aria-label="instagram-link"
                href="/"
              >
                <Image
                  loading="lazy"
                  className="sidebar-link-facebook"
                  src="/assets/images/icons/facebook-logo.svg"
                  alt="facebook icon"
                />
              </a>
              <a
                className="bg-sidebar-link-email"
                aria-label="instagram-link"
                href="/"
              >
                <Image
                  loading="lazy"
                  className="sidebar-link-logos"
                  src="/assets/images/icons/mail-logo.svg"
                  alt="email icon"
                />
              </a>
              <a
                className="bg-sidebar-link-logos"
                aria-label="instagram-link"
                href="/"
              >
                <Image
                  loading="lazy"
                  className="sidebar-link-logos"
                  src="/assets/images/icons/whatsapp-logo.svg"
                  alt="WhatsApp icon"
                />
              </a>
            </div>

            <Image
              loading="lazy"
              className="sidebar-halal-logo"
              src="/assets/images/icons/halal-logo.svg"
              alt="halal logo "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MobileSidebar.propTypes = {
  toggleSidebar: PropTypes.bool.isRequired,
  toggleMiniSidebar: PropTypes.func.isRequired,
  isOpenMiniSidebar: PropTypes.string.isRequired,
};

import { Image } from "semantic-ui-react";
import "./ChoosePinchBakehouse.css";
import useCheckDevice from "hooks/useCheckDevice";

const ChoosePinchBakehouse = () => {
  const isDesktop = useCheckDevice();
  const iconTitles = [
    {
      title: "HALAL-CERTIFIED",
      // title: "HALAL-CERTIFIED",
      // description: "Pinch Bakehouse is MUIS Halal-certified.",
      description: "Pinch Bakehouse is a Halal-certified brand.",
      img: "/assets/images/icons/halal-logo.svg",
      width: isDesktop ? "w-[100px]" : "w-[80px]",
    },
    {
      title: "CUSTOMISATION",
      description: "Customisation is available for all desserts and packaging.",
      img: "/assets/images/icons/PINCH_icon_gifting.svg",
      width: isDesktop ? "w-[100px]" : "w-[80px]",
    },
    {
      title: "ALLERGEN FRIENDLY",
      description:
        "We have nut, egg, dairy, and gluten-free options available.",
      img: "/assets/images/icons/PINCH_icon_dietary.svg",
      width: isDesktop ? "w-[95px]" : "w-[80px]",
    },
    {
      title: "LESS SUGAR",
      description:
        "Choose Healthier Choice desserts that contain under 12 grams of sugar per serving.",
      img: "/assets/images/icons/PINCH_icon_Less_Sugar.png",
      width: isDesktop ? "w-[100px]" : "w-[250px]",
    },
  ];

  return (
    <div className="bg-primary-peach-20 pt-16 pb-12 xl:py-20 flex  flex-col w-full items-center gap-12">
      <h1 className="title-60 mx-20 md:mx-0  ">Choose Pinch Bakehouse</h1>
      <div className="flex gap-x-8 gap-y-10 md:gap-16 lg:gap-16 xl:gap-20 flex-wrap justify-center  items-start ">
        {iconTitles.map((iconTitle) => {
          return (
            <div
              key={crypto.randomUUID()}
              className="flex  flex-col lg:w-[220px] w-[150px]"
            >
              <Image
                alr={iconTitle.title}
                loading="lazy"
                className={`mb-4  ${iconTitle.width}  pb-4 self-center`}
                alt="Packaging image"
                src={iconTitle.img}
              />
              <p className="family-inter text-[0.9rem]  md:text-[1rem] font-semibold self-center mb-2 ">
                {iconTitle.title}
              </p>
              <p className="py-2 family-inter text-[0.9rem]  md:text-[1rem] font-normal text-center ">
                {iconTitle.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChoosePinchBakehouse;

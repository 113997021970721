// import { UilArrowRight } from "@iconscout/react-unicons";
import { Image } from "semantic-ui-react";
import "./Footer.css";
import { footerDes } from "components/utils/constants/messageConstants";
import {
  BLOGS,
  CONTACT_US,
  FAQ,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
} from "components/utils/constants/routeConstants";

const Footer = () => {
  const HALAL_DESC =
    "  At Pinch Bakehouse, we make gifting easier for you through our wide range of easily customisable cakes and desserts that come in beautiful and gift-worthy packaging. We pride ourselves in being an all-inclusive brand – halal-certified cakes, allergen-free cakes, sugar-free cakes – you name it, we have it!";
  return (
    <footer
      id="footer"
      className="footer bg-black xl:px-20 xl:py-28 py-20 px-10  text-white  flex flex-col justify-center md:items-center flex-wrap"
    >
      {/* first row of footer */}
      <div className="footer-row-1 flex flex-wrap md:justify-between items-start xl:w-[1100px] lg:w-[1000px] md:w-[700px] w-full gap-y-10 gap-x-24  md:gap-0 md:m-auto">
        {/* 1st column */}
        <div className="flex flex-col gap-9 sm:w-full md:w-[250px]">
          <Image
            loading="lazy"
            className="footer-pinch-logo"
            alt="Pinch Logo"
            src="/assets/images/logo/pinch-logo.svg"
            size="small"
          />

          {/* <div className="flex flex-col gap-1">
            <div id="subscribe" className="footer-text title-60 text-white ">
              Subscribe
            </div>
            <p className=" text-white para-25 footer-text">
              {footerDes.subscribeMsg}
            </p>

            <Form success className="flex">
              <Input
                type="email"
                className="email-input-footer mt-2"
                transparent
                placeholder="Email Address"
              />
              <button aria-label="email address submit" type="submit">
                <UilArrowRight className="cursor-pointer" />
              </button>
            </Form>
          </div> */}
        </div>

        {/* 2nd column */}
        <div
          className="para-25 footer-text flex gap-2 flex-col w-[110px] "
          id="company "
        >
          <p className="font-semibold footer-heading">COMPANY</p>
          {/* <a href="/" className="footer-link-page para-25 footer-text">
            About Us
          </a>
          <a href="/" className="footer-link-page para-25 footer-text">
            Join Us
          </a> */}
          <a
            href={PRIVACY_POLICY}
            className="footer-link-page para-25 footer-text"
          >
            Privacy Policy
          </a>
          <a
            href={TERMS_CONDITIONS}
            className="footer-link-page para-25 footer-text"
          >
            T&C
          </a>
          <a
            target="blank"
            href={BLOGS}
            className="footer-link-page para-25 footer-text"
          >
            Blogs
          </a>
        </div>
        {/* <div
          className="para-25 footer-text flex gap-2 flex-col w-[110px] "
          id="corporate"
        >
          <p className="font-semibold footer-heading ">CORPORATE </p>
          <a href="/" className="footer-link-page para-25 footer-text">
            Corporate Order
          </a>
        </div> */}
        <div
          className="para-25 footer-text flex gap-2 flex-col w-[110px]"
          id="help"
        >
          <p className="font-semibold footer-heading  ">HELP </p>
          <a
            target="blank"
            href={FAQ}
            className="footer-link-page para-25 footer-text"
          >
            FAQ
          </a>
          <a href={CONTACT_US} className="footer-link-page para-25 footer-text">
            Contact us
          </a>
        </div>
      </div>

      {/* second row of footer */}
      <div className="footer-row-2 flex flex-wrap-reverse gap-10 justify-between items-center xl:w-[1100px] lg:w-[1000px] md:w-[700px] w-full md:m-auto">
        <div className="flex gap-5 flex-col flex-wrap  ">
          <div className="flex justify-center items-center md:gap-10 gap-4   ">
            <p className="title-60 footer-text">Follow Us</p>
            <div>
              {/* for all social logos */}
              <ul className="flex md:gap-5 gap-4 ">
                <li>
                  <a
                    target="blank"
                    aria-label="instagram-link"
                    className="cursor-pointer"
                    href="https://www.instagram.com/pinchbakehouse?igsh=Y2ZmazQ1ODRlb2h3"
                  >
                    <Image
                      loading="lazy"
                      className="w-[35px]"
                      src="/assets/images/icons/Socialmediaicons/PINCH_icon_ig.png"
                      alt="instagram icon"
                    />
                  </a>
                </li>
                {/* facebook-link */}
                <li>
                  <a
                    target="blank"
                    className="cursor-pointer"
                    aria-label="facebook-link"
                    href="https://www.facebook.com/people/Pinch-bakehouse/61559524082619/"
                  >
                    <Image
                      loading="lazy"
                      className="w-[35px]"
                      src="/assets/images/icons/Socialmediaicons/PINCH_icon_fb.png"
                      alt="facebook icon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    className="cursor-pointer"
                    aria-label="email-link"
                    href="mailto:hello@pinchbakehouse.com"
                  >
                    <Image
                      loading="lazy"
                      className="w-[35px]"
                      src="/assets/images/icons/Socialmediaicons/PINCH_icon_mail.png"
                      alt="email icon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    className="cursor-pointer"
                    aria-label="instagram-link"
                    href="https://wa.me/message/MLKNTMUWBINCA1"
                  >
                    <Image
                      loading="lazy"
                      className="w-[35px]"
                      src="/assets/images/icons/Socialmediaicons/PINCH_icon_wa.png"
                      alt="whatsapp icon"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex  flex-col gap-1">
            <p className="footer-copyright">{footerDes.policy}</p>
            <p className="footer-copyright">{footerDes.copyRight}</p>
          </div>
        </div>

        <div className="bg-primary-freshmint-20  flex items-center self-end px-6 py-4 rounded-sm gap-3">
          <Image
            loading="lazy"
            src="/assets/images/icons/halal-logo.svg"
            alt="halal logo"
            className="w-[70px] bg-white"
          />
          <div className="flex flex-col w-[250px] text-left">
            <p className="text-black uppercase font-display font-medium tracking-normal text-left mb-1 text-[1rem]  md:text-[1.2rem]">
              We&apos;re Halal-Certified!
            </p>
            <p className="font-inter tracking-normal font-normal text-[10px] text-black">
              {HALAL_DESC}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

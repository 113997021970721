import "./Articles.css";
import { Helmet } from "react-helmet";
import { CHILDREN_DAY } from "components/utils/constants/urlConstants";
import Footer from "../Footer/Footer";
// eslint-disable-next-line quotes

const ArticleSix = () => {
  return (
    <>
      <Helmet>
        <title>Certified Gifts from Pinch Bakehouse</title>
        <meta
          name="description"
          content="Discover the perfect Children's Day gifts with Pinch Bakehouse's halal certified, allergen-friendly treats. From sugar-free cookies to customizable party packs, find delightful options to make your celebrations extra special!"
        />
      </Helmet>
      <div className="m-auto max-w-[900px] bg-primary-cream-20 px-8 py-10 lg:py-20 lg:px-0 flex items-center justify-center flex-col">
        <div className="max-w-[750px] mt-6">
          <h1 className="blog-title-60 !text-left ">
            Children&apos;s Day Gifts: Halal Certified Treats That Shine in
            School!
          </h1>
          <p className="para-25 !text-left mt-6">
            Hey there, awesome gift-givers and party planners! 🎉 With
            Children&apos;s Day just around the corner, it&apos;s time to think
            about how to make it extra special for the little ones. Whether
            you&apos;re a parent, teacher, or just a fantastic friend, you want
            to find gifts that are thoughtful, fun, and, of course, delicious!
            And guess what? Pinch Bakehouse is here to make your gifting
            easy-peasy with our range of halal certified goodies that everyone
            will adore.
          </p>
          <h2 className="title-40 !text-left mt-6">
            A Sweet Way to Celebrate with Halal Desserts Singapore
          </h2>
          <p className="para-25 !text-left mt-6">
            At Pinch Bakehouse, we believe that celebrating Children&apos;s Day
            should be as joyful as the occasion itself. That&apos;s why
            we&apos;ve crafted a collection of halal desserts that are perfect
            for gifting, sharing, and spreading happiness. Let&apos;s dive into
            some of our standout treats that will surely make you the star of
            the day!
          </p>
          <img
            loading="lazy"
            src={CHILDREN_DAY}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-full lg:h-[500px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">
            1. Sugar-Free Chocolate Chip Cookies: A Health-Conscious Delight
          </h2>
          <p className="para-25 !text-left mt-6">
            For those who are mindful of their sugar intake but still crave
            something sweet, our{" "}
            <span className="font-bold">
              80g Sugar-Free Chocolate Chip Cookies
            </span>{" "}
            are the ideal choice. Priced at just $4.90, these cookies are
            perfect for the health-conscious teachers and moms. Imagine the
            delight on their faces as they enjoy a treat that’s not only
            delicious but also fits perfectly into a healthier lifestyle. With
            less than 12g of sugar per serving, you can indulge guilt-free!
          </p>
          <h2 className="title-40 !text-left mt-6">
            2. &quot;Happy to Have You in My Class&quot; Caramelised Butter
            Cookies
          </h2>
          <p className="para-25 !text-left mt-6">
            This is my favourite! Looking for something that will win hearts?
            Our{" "}
            <span className="font-bold">
              “Happy to Have You in My Class” Caramelised Butter Cookies
            </span>
            , also at $4.90, are a surefire hit! Teachers, these cookies are a
            cute and thoughtful way to show your students just how much you
            appreciate them. Mommies, these treats are bound to make your child
            the talk of the class. Their rich caramelised flavor and charming
            packaging will make anyone smile and feel special.
          </p>
          <h2 className="title-40 !text-left mt-6">
            3. Children’s Day Party Pack Edition: A Symphony of Flavors
          </h2>
          <p className="para-25 !text-left mt-6">
            Throwing a Children’s Day party? Our{" "}
            <span className="font-bold">Children’s Day Party Pack Edition</span>{" "}
            is here to save the day with an assortment of mini treats that will
            make sharing a blast! This colorful box includes a delightful
            variety of goodies:
          </p>
          <p className="para-25 !text-left mt-6">
            <ul className="mt-6">
              <li className="para-25 !text-left">
                <span className="font-bold">● Lemon Meringue Tartlet:</span> A
                zesty burst of citrus.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">● Chocolate Chip Cupcake:</span> A
                Classic and comforting.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">● Uji Matcha Tartlet:</span> A A
                green tea twist on a classic favorite.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">
                  ● Salted Caramel Brownie Tartlet:
                </span>{" "}
                Sweet and salty perfection.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">
                  ● White Chocolate Cheese Brûlée Tartlet:
                </span>{" "}
                A creamy and indulgent treat.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">● Cream Cheese Cupcake:</span>{" "}
                Smooth and satisfying.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">● Strawberry Cream Cupcake: </span>{" "}
                Fruity and fluffy.
              </li>
              <li className="para-25 !text-left">
                <span className="font-bold">
                  ● Speculoos Chantilly Cupcake:{" "}
                </span>{" "}
                Spiced with love.
              </li>
            </ul>
            <p className="para-25 !text-left mt-6">
              Each treat is approximately 3cm, making them perfect for sharing
              and snacking. This pack is sure to add a fun, flavorful touch to
              any party and is priced just right for all your celebrations!
            </p>
          </p>
          <h2 className="title-40 !text-left mt-6">
            Why Choose Pinch Bakehouse?
          </h2>
          <p className="para-25 !text-left mt-6">
            You might be wondering what makes Pinch Bakehouse the go-to choice
            for Children’s Day gifts. Let’s break it down:
          </p>
          <h2 className="title-40 !text-left mt-6">
            1. Gorgeous Packaging: Look Good, Gift Well
          </h2>
          <p className="para-25 !text-left mt-6">
            Our treats come in bright-colored, high-quality packaging that makes
            gifting effortless. Whether it&apos;s for a school event or a
            personal gift, our packaging ensures that your gift stands out and
            makes a great impression.
          </p>
          <h2 className="title-40 !text-left mt-6">
            2. Halal-Certified Goodness
          </h2>
          <p className="para-25 !text-left mt-6">
            We know how important it is to respect dietary needs and cultural
            preferences. All our products are halal certified, so you can feel
            confident that your gift is suitable for all your friends,
            especially your Muslim friends.
          </p>
          <h2 className="title-40 !text-left mt-6">3. Allergen Friendly</h2>
          <p className="para-25 !text-left mt-6">
            With our allergen-friendly options, no one has to miss out on the
            fun. We’re dedicated to being inclusive, ensuring everyone can enjoy
            our treats without worry.
          </p>
          <h2 className="title-40 !text-left mt-6">4. Healthier Choices</h2>
          <p className="para-25 !text-left mt-6">
            Our sugar-free options and treats with less than 12g sugar per
            serving are perfect for those who are health-conscious but still
            want to indulge. It’s all about enjoying the best of both worlds!
          </p>

          <h2 className="title-40 !text-left mt-6">
            5. Customizable Creations
          </h2>
          <p className="para-25 !text-left mt-6">
            Want to add a personal touch? We offer customizable options so you
            can tailor your gifts to make them extra special. Show that you
            really care with something uniquely yours.
          </p>
          <h2 className="title-40 !text-left mt-6">Get in Touch!</h2>
          <p className="para-25 !text-left mt-6">
            Ready to make Children’s Day unforgettable with Pinch Bakehouse? Our
            friendly customer service team is here to help you with all your
            gifting needs. Reach out to us via WhatsApp at +65 8380 6722
            (message only) or drop us an{" "}
            <a
              target="blank"
              className="cursor-pointer underline"
              aria-label="email-link"
              href="mailto:hello@pinchbakehouse.com"
            >
              email
            </a>
            .
          </p>

          <p className="para-25 !text-left mt-6">
            Celebrate Children’s Day with the perfect mix of thoughtfulness and
            taste. With Pinch Bakehouse, you’re not just giving a gift; you’re
            spreading joy and making memories!
          </p>
          <p className="para-25 !text-left mt-6">
            So, go ahead and make this Children’s Day one to remember with our
            delightful, halal-certified treats. Because at Pinch Bakehouse, we
            believe that every celebration deserves a touch of sweetness and a
            whole lot of love. Happy gifting! 🎁✨
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleSix;

export const HOME = "/";
export const ALL_CAKES = "/all-cakes";
export const ALL_BAKES = "/all-bakes";
export const CAKE_DETAILS = "/details/:slug";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const ACCOUNT = "/account";
export const BLOGS = "/blogs";
export const ADDRESS_BOOK = "/address";
export const CHANGE_PWD = "/change-password";
export const RESET_PWD = "/reset-password/:id";
export const FORGOT_PWD = "/forgot-password";
export const CAKE_CONCIERGE = "/cake-concierge";
export const CART_PAGE = "/cart";
export const ORDER_CONFIRMATION = "/order-confirm/:id";
export const CHECKOUT = "/checkout";
export const PAYMENT_FAIL = "/retry-payment/:id";
export const ORDER_DETAILS = "/order/:id";
export const FAQ = "/frequently-asked-questions";
export const CONTACT_US = "/contact-us";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_CONDITIONS = "/terms-conditions";

// Articles urls
export const ARTICLE_ONE = "/best-halal-cakes-singapore";
export const ARTICLE_TWO = "/cake-halal-certification";
export const ARTICLE_THREE = "/dessert-fix-pinch-bakehouse";
export const ARTICLE_FOUR = "/halal-trends-spotted";
export const ARTICLE_FIVE = "/sweet-world-halal-baking-pastries";
export const ARTICLE_SIX = "/childrens-day-gifts-halal-certified-treats";
export const ARTICLE_SEVEN = "/top-3-local-cake-flavours";
export const ARTICLE_EIGHT = "/crazy-unique-halal-cake-flavours";
export const ARTICLE_NINE =
  "/are-halal-cakes-more-expensive-than-non-halal-cakes";
export const ARTICLE_TEN = "/customize-halal-cake-for-special-occasions";
export const ARTICLE_ELEVEN =
  "/tips-for-decorating-halal-customised-cakes-with-pinch-bakehouse";
export const ARTICLE_TWELVE =
  "/how-to-make-gluten-free-cakes-your-guide-to-gluten-free-flour-blend";
export const ARTICLE_THIRTEEN =
  "/top-3-best-selling-pastries-for-your-next-party";
export const ARTICLE_FOURTEEN =
  "/how-to-order-a-halal-cake-online-for-doorstep-delivery";
// export const ARTICLE_FIFTEEN = "/customize-halal-cake-for-special-occasions";

import CommonVioletHeader from "components/generic/CommonVioletHeader/CommonVioletHeader";
import { HOME } from "components/utils/constants/routeConstants";
import { useNavigate } from "react-router-dom";
import "./BlogPage.css";
import {
  allBlogContent,
  featuredBlogContent,
} from "components/utils/constants/messageConstants";
import Footer from "../Footer/Footer";

const BlogPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-primary-cream-20">
      <CommonVioletHeader
        title="Blogs"
        backLinkName="< Go back to Home"
        onClick={() => navigate(HOME)}
      />
      <div className="max-w-[1100px] m-auto my-16 flex gap-16 flex-col px-4 lg:px-8">
        {/* featured blogs container */}
        <div className="featured-blogs-container">
          <h1 className="title-60 !text-left">Featured Blogs</h1>
          <div className="flex gap-4 lg:gap-8 mt-8">
            {featuredBlogContent.map(
              ({ title, subTitle, image, navigateLink }) => {
                return (
                  <a
                    target="blank"
                    href={navigateLink}
                    className="w-[50%] flex gap-2 flex-col cursor-pointer"
                  >
                    <img
                      loading="lazy"
                      src={image}
                      className="object-cover h-[190px] lg:h-[350px] rounded-sm"
                      alt="halal-cake"
                    />
                    <h1 className="featured-blog-title ">{title}</h1>
                    <p className="featured-blog-para">{subTitle}</p>
                    <p className="tracking-normal underline underline-offset-4 cursor-pointer font-[600] mt-2 text-primary-orange text-[1.1rem] lg:text-[1.2rem] font-inter">
                      Read blog &gt;
                    </p>
                  </a>
                );
              },
            )}
          </div>
        </div>
        {/* all-blogs */}
        <div className="all-blogs-container mb-10">
          <h1 className="title-60 !text-left">All Blogs</h1>
          <div className="flex mt-8 flex-wrap w-full gap-y-10">
            {allBlogContent.map(({ title, subTitle, image, navigateLink }) => (
              <a
                target="blank"
                href={navigateLink}
                className="min-w-[100px] w-[calc(100%/2)] lg:w-[calc(100%/3)] flex gap-2 flex-col px-2 lg:px-3"
              >
                <img
                  loading="lazy"
                  src={image}
                  className="object-cover h-[140px] lg:h-[250px]"
                  alt="halal-cake"
                />
                <h1 className="all-blogs-title ">{title}</h1>
                <p className="all-blogs-para">{subTitle}</p>
                <p className="tracking-normal underline underline-offset-4 cursor-pointer font-[600] mt-2 text-primary-orange text-[1rem] lg:text-[1.1rem] font-inter">
                  Read blog &gt;
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPage;

import {
  BLOG_13_IMAGES,
  BLOG_13_IMAGES_1,
  BLOG_13_IMAGES_2,
  BLOG_13_IMAGES_3,
} from "components/utils/constants/urlConstants";
import "./Articles.css";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";

const ArticleThirteen = () => {
  return (
    <>
      <Helmet>
        <title>
          Insider Tip from Pinch Bakehouse: Top 3 Best Selling Pastries for Your
          Next Party
        </title>
        <meta
          name="description"
          content="Discover the top 3 best-selling pastries from Pinch Bakehouse that are perfect for your next party. From assorted party packs to aesthetic brownies and charming cupcakes, we’ve got the treats that will make your event a hit!"
        />
      </Helmet>
      <div className="m-auto max-w-[900px] bg-primary-cream-20 px-8 py-10 lg:py-20 lg:px-0 flex items-center justify-center flex-col">
        <img
          loading="lazy"
          src={BLOG_13_IMAGES}
          alt="halal-cake"
          className="object-cover w-full h-[300px] lg:w-[750px] lg:h-[500px]"
        />
        <div className="max-w-[750px] mt-6">
          <h1 className="blog-title-60 !text-left ">
            Insider Tip from Pinch Bakehouse: Our Top 3 Best Selling Pastries
            for Your Next Party
          </h1>

          <h2 className="title-40 !text-left mt-6">
            The Ultimate Party Pleasers: Our Best Selling Pastries
          </h2>

          <p className="para-25 !text-left mt-6">
            Hello, party planners and pastry lovers! 🎉 If you’re looking to
            impress your guests with the most scrumptious and beautifully
            presented treats, you’re in for a treat! At Pinch Bakehouse, we
            pride ourselves on creating delectable pastries that are not just
            tasty but also halal-certified and allergen-friendly. Whether you’re
            hosting an office meeting, a farewell party, or simply a
            get-together, here are our top 3 best-selling pastries that are
            guaranteed to make your event a smashing success.
          </p>

          <img
            loading="lazy"
            src={BLOG_13_IMAGES_1}
            alt="halal-pastry"
            className="object-cover w-full  h-[400px] lg:w-[500px] lg:h-[500px] m-auto my-8"
          />
          <h2 className="title-40 !text-left">
            1. Assorted Party Pack (25 Pieces)
          </h2>

          <p className="para-25 !text-left mt-6">
            Price: <span className="font-semibold">$39.95</span>
          </p>

          <p className="para-25 !text-left mt-4">
            When it comes to sharing delightful pastries at any event, our
            Assorted Party Pack is a total winner! This pack includes 25 pieces
            of our most beloved pastries, making it perfect for office meetings,
            parties, and farewells. The variety ensures there’s something for
            everyone, and the beautifully bright, high-quality packaging means
            you’ll look like a gift-giving superstar.
          </p>

          <h2 className="title-40 !text-left mt-6">Why It’s a Hit:</h2>

          <div className="mt-4">
            <p className="para-25 !text-left">
              <span className="font-semibold">Perfect for Sharing:</span> With
              25 pieces, this pack is ideal for gatherings where you want to
              offer a range of treats without breaking the bank.
            </p>
            <p className="para-25 !text-left mt-3">
              <span className="font-semibold">Versatility:</span> From sweet to
              savory, our assorted pack covers all bases, making it a go-to
              choice for any occasion.
            </p>
            <p className="para-25 !text-left mt-3">
              <span className="font-semibold">Effortless Appeal:</span> No need
              to stress about presentation; our party pack comes in gorgeous
              packaging that makes it party-ready.
            </p>
          </div>

          <p className="para-25 !text-left mt-6">
            <a
              className="text-primary-orange hover:text-primary-orange font-medium text-[1.1rem]"
              href="https://www.pinchbakehouse.com/details/Fan-Favourites-Mini-Bites-(25-pieces)"
            >
              Explore the Assorted Party Pack here!
            </a>
          </p>

          {/* 2nd product */}

          <img
            loading="lazy"
            src={BLOG_13_IMAGES_2}
            alt="halal-pastry"
            className="object-cover w-full lg:w-[500px] lg:h-[500px] m-auto my-8"
          />
          <h2 className="title-40 !text-left">
            2. Assorted Brownie Box (Box of 16)
          </h2>

          <p className="para-25 !text-left mt-6">
            Price: <span className="font-semibold">$39.95</span>
          </p>

          <p className="para-25 !text-left mt-4">
            If you’re looking for a treat that combines aesthetics with
            indulgence, our Assorted Brownie Box is where it’s at! Packed with
            16 brownies, this box is a feast for the eyes and the taste buds.
            Our brownies strike the perfect balance of sweetness and fudginess,
            making them the ultimate dessert choice for any gathering.
          </p>

          <h2 className="title-40 !text-left mt-6">Why You’ll Love It:</h2>

          <div className="mt-4">
            <p className="para-25 !text-left">
              <span className="font-semibold">Aesthetic Appeal:</span>
              This signature box is as stunning as it is delicious, making it a
              fantastic gift or centerpiece for your dessert table.
            </p>
            <p className="para-25 !text-left mt-3">
              <span className="font-semibold">Delicious Variety:</span> Our
              brownies come in a delightful range of flavors, including
              Peppermint Chocolate, Midnight Brownie, Floral Rose Blondie, and
              Crunchy Speculoos Blondie. Each bite is a unique experience!
            </p>
            <p className="para-25 !text-left mt-3">
              <span className="font-semibold">Perfectly Sweet:</span>
              We’ve nailed the recipe to ensure just the right amount of
              sweetness and gooeyness.
            </p>
          </div>

          <p className="para-25 !text-left mt-6">
            Check out our{" "}
            <a
              className="text-primary-orange hover:text-primary-orange font-medium text-[1.1rem]"
              href="https://www.pinchbakehouse.com/details/Assorted-Brownie-Box-(Box-of-16)"
            >
              Assorted Brownie Box (Box of 16)
            </a>
          </p>

          <p className="para-25 !text-left mt-4">
            Need a smaller option? Check out our{" "}
            <a
              className="text-primary-orange hover:text-primary-orange font-medium text-[1.1rem]"
              href="https://www.pinchbakehouse.com/details/Assorted-Brownie-Box-(Box-of-4)"
            >
              Assorted Brownie Box (Box of 4)
            </a>{" "}
            for just $9.95—great for smaller gatherings or as a personal treat!
          </p>

          {/* 3rd product */}
          <img
            loading="lazy"
            src={BLOG_13_IMAGES_3}
            alt="halal-pastry"
            className="object-cover w-full  h-[400px] lg:w-[500px] lg:h-[500px] m-auto my-8"
          />
          <h2 className="title-40 !text-left">
            3. Mixed Cupcake Selection (6 Pieces)
          </h2>

          <p className="para-25 !text-left mt-6">
            Price: <span className="font-semibold">$19.95</span>
          </p>
          <p className="para-25 !text-left mt-4">
            Cupcakes are a timeless favorite, and our Mixed Cupcake Selection is
            a perfect example of why. These 2.5 diameter cupcakes are just the
            right size for satisfying your sweet tooth without overwhelming you.
            They’re moist, flavorful, and ideal for any event.
          </p>
          <h2 className="title-40 !text-left mt-6">Why Cupcakes Rock:</h2>

          <div className="mt-4">
            <p className="para-25 !text-left">
              <span className="font-semibold"> Perfect Portion:</span>
              Our cupcakes are perfectly sized to be enjoyed in a few bites,
              making them ideal for an event where guests might want to sample
              multiple treats.
            </p>
            <p className="para-25 !text-left mt-3">
              <span className="font-semibold">Customizable:</span> You can even
              customize these cupcakes with logos or messages, adding a personal
              touch to your party.
            </p>
            <p className="para-25 !text-left mt-3">
              <span className="font-semibold">Versatile Treat:</span>
              Whether you’re packing them individually for gifting or serving
              them as is, these cupcakes are a crowd-pleaser.
            </p>

            <p className="para-25 !text-left mt-3">
              Our cupcakes also come in a variety of flavors to cater to all
              palates. They’re not just desserts; they’re little bites of
              happiness!
            </p>
          </div>

          <h2 className="title-40 !text-left mt-6">
            Why Choose Pinch Bakehouse for Your Party Treats?
          </h2>
          <p className="para-25 !text-left mt-6">
            At Pinch Bakehouse, we believe that every occasion deserves a touch
            of sweetness and a lot of care. Here’s why our pastries are the top
            choice for your next event:
          </p>

          <div className="mt-6">
            <p className="para-25 !text-left ">
              {" "}
              <span className="font-semibold">
                1. Bright, Giftable Packaging:
              </span>
              Our pastries come in high-quality, vibrant packaging that makes
              gifting easy and stylish. You’ll look like a superstar host!
            </p>
            <p className="para-25 !text-left mt-3">
              {" "}
              <span className="font-semibold">2. Halal-Certified: </span>
              All our treats are made with halal-certified ingredients, so you
              can enjoy them with peace of mind.
            </p>
            <p className="para-25 !text-left mt-3">
              {" "}
              <span className="font-semibold"> 3. Allergen-Friendly:</span>
              We offer a range of allergen-friendly options, including
              gluten-free, egg-free, and dairy-free choices, ensuring everyone
              at your event can indulge.
            </p>
            <p className="para-25 !text-left mt-3">
              {" "}
              <span className="font-semibold">4. Healthier Choices:</span> With
              sugar-free options and cakes containing less than 12g of sugar per
              serving, you can treat yourself and your guests without the extra
              sugar.
            </p>{" "}
            <p className="para-25 !text-left mt-3">
              {" "}
              <span className="font-semibold"> 5. Customisable:</span> Our cakes
              and pastries are fully customisable to suit your event’s theme and
              your guests’ preferences.
            </p>
          </div>

          <p className="para-25 !text-left mt-6">
            Ready to make your next party unforgettable with our top-selling
            pastries? Contact us via WhatsApp at +65 8380 6722 or email us at
            hello@pinchbakehouse.com to place your order. We’re here to help you
            make every event a sweet success!
          </p>

          <p className="para-25 !text-left mt-6">
            So there you have it—our top 3 best-selling pastries that are
            perfect for any party. Whether you’re ordering for a big event or a
            small gathering, Pinch Bakehouse has got the treats that will make
            your occasion extra special. Cheers to sweet celebrations and happy
            guests! 🥳🍰
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleThirteen;
